import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';
import ReactWorldFlags from 'react-world-flags';
import stationsData from '../stationsListCountry.json';
import topStationsData from '../topStationsList.json';
import cityTranslationMap from '../dutchToNativeCityMap.json';

// Custom MenuList using react-window for efficient rendering
const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 35;

  // Find the current selected option to set initial scroll position
  const [value] = getValue();
  const initialOffset = options.findIndex(option => option.value === value);

  // Render each row
  const renderRow = ({ index, style }) => (
    <div style={style} key={index} {...props.innerProps}>
      {children[index]}
    </div>
  );

  if (options.length > 0) {
    return (
      <List
        height={options.length > 0 ? Math.min(maxHeight, options.length * height) : 0} // Yes, dubplicate, but needed because of potential race conditions.
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset * height}
      >
        {renderRow}
      </List>
    );
  }
};

// Main CityPicker component
const CityPicker = ({ onSelect, placeholder = "Select a city" }) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Helper functions
  const normalizeText = (text) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const translateStationName = (cityPart, stationName) => {
    const cityName = cityPart.charAt(0).toUpperCase() + cityPart.slice(1);
    
    // Check if stationName is exactly the city name (case-insensitive)
    if (stationName.toLowerCase() === cityPart.toLowerCase()) {
      return cityName;
    }

    // Check if stationName starts with the city name followed by a space
    const cityRegex = new RegExp(`^${cityPart}\\s+`, 'i');
    if (cityRegex.test(stationName)) {
      // Remove the city part and return the translated name with the suffix
      const stationPart = stationName.replace(cityRegex, '');
      return `${cityName} ${stationPart}`;
    }

    // If stationName doesn't contain the city part, return it unchanged or handle accordingly
    return stationName;
  };

  // Create a memoized search index
  const searchIndex = useMemo(() => {
    const index = new Map();
    
    [...stationsData, ...topStationsData].forEach((station) => {
      // Add original station name
      const normalizedName = normalizeText(station.name.toLowerCase());
      index.set(normalizedName, station);
      
      // Add translations
      Object.entries(cityTranslationMap).forEach(([dutch, native]) => {
        if (station.name.includes(native)) {
          const translatedName = translateStationName(dutch, station.name.replace(native, dutch));
          const normalizedTranslation = normalizeText(translatedName.toLowerCase());
          index.set(normalizedTranslation, {
            ...station,
            name: translatedName
          });
        }
      });
    });
    
    return index;
  }, []);

  // Helper function to create rich markup for a city
  const createRichCityLabel = (cityName) => {
    const matchingStation = [...stationsData, ...topStationsData].find(
      station => station.name === cityName
    );

    return {
      value: cityName,
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {matchingStation && (
            <ReactWorldFlags
              code={matchingStation.country.toUpperCase()}
              style={{ width: '24px', height: '18px', marginRight: '8px' }}
              alt={`${matchingStation.country} flag`}
            />
          )}
          <span>{cityName}</span>
          {matchingStation?.topStation && (
            <span
              style={{
                backgroundColor: 'var(--tomato)',
                color: 'white',
                padding: '2px 8px',
                borderRadius: '12px',
                marginLeft: '8px',
                fontSize: '12px'
              }}
            >
              Populair
            </span>
          )}
        </div>
      )
    };
  };

  // Helper function to generate variations of station names
  const generateNameVariations = (slug) => {
    const parts = slug.split('-');
    const capitalizedParts = parts.map(word => {
      // Special case mappings for common German stations
      const specialMappings = {
        'dusseldorf': 'Düsseldorf',
        'koln': 'Köln',
        'munchen': 'München',
        'nurnberg': 'Nürnberg',
      };
      
      const normalized = word.toLowerCase();
      if (specialMappings[normalized]) {
        return specialMappings[normalized];
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    
    return [
      capitalizedParts.join('-'),    // e.g., "Amsterdam-Centraal"
      capitalizedParts.join(' '),    // e.g., "Amsterdam Centraal"
      capitalizedParts.slice(0, -1).join(' ') + '-' + capitalizedParts.slice(-1), // e.g., "Marseille Saint-Charles"
    ];
  };

  // Initial setup for placeholder value
  useEffect(() => {
    if (placeholder) {
      const variations = generateNameVariations(placeholder);
      const matchingStation = [...stationsData, ...topStationsData].find(
        station => variations.some(variant => 
          station.name.toLowerCase() === variant.toLowerCase()
        )
      );

      if (matchingStation) {
        setSelectedCity(createRichCityLabel(matchingStation.name));
      }
    }
  }, [placeholder]);

  // Helper function to bold matching characters
  const highlightMatch = (text, query) => {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? <strong key={index}>{part}</strong> : part
    );
  };

  // Effect to handle search input and generate options
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const query = inputValue.trim().toLowerCase();
      const normalizedQuery = normalizeText(query);

      if (query.length >= 2) {
        const uniqueStations = new Set();
        const results = [];

        // Search through pre-processed index
        searchIndex.forEach((station, indexKey) => {
          if (indexKey.includes(normalizedQuery)) {
            const stationKey = station.name.toLowerCase();
            if (!uniqueStations.has(stationKey)) {
              uniqueStations.add(stationKey);
              results.push(station);
            }
          }
        });

        // Define priority station suffixes
        const priorityStations = ['centraal', 'centrum', 'central', 'hauptbahnhof', 'hbf'];
        
        // Sort results: popular stations first, then priority stations, then alphabetically
        results.sort((a, b) => {
          // First sort by topStation
          if (a.topStation && !b.topStation) return -1;
          if (!a.topStation && b.topStation) return 1;

          // Then check if station name is just the city name (no spaces)
          const aIsJustCity = !a.name.includes(' ');
          const bIsJustCity = !b.name.includes(' ');
          if (aIsJustCity && !bIsJustCity) return -1;
          if (!aIsJustCity && bIsJustCity) return 1;

          // Then sort by priority station names
          const aStation = a.name.toLowerCase().split(' ').slice(1).join(' ');
          const bStation = b.name.toLowerCase().split(' ').slice(1).join(' ');
          const aIsPriority = priorityStations.includes(aStation);
          const bIsPriority = priorityStations.includes(bStation);
          if (aIsPriority && !bIsPriority) return -1;
          if (!aIsPriority && bIsPriority) return 1;

          // Finally, sort alphabetically
          return a.name.localeCompare(b.name);
        });

        // Set options as before
        setOptions(results.map(station => ({
          value: station.name,
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ReactWorldFlags
                code={station.country.toUpperCase()}
                style={{ width: '24px', height: '18px', marginRight: '8px' }}
                alt={`${station.country} flag`}
              />
              <span>{highlightMatch(station.name, inputValue)}</span>
              {station.topStation && (
                <span
                  style={{
                    backgroundColor: 'var(--tomato)',
                    color: 'white',
                    padding: '2px 8px',
                    borderRadius: '12px',
                    marginLeft: '8px',
                    fontSize: '12px'
                  }}
                >
                  Populair
                </span>
              )}
            </div>
          ),
        })));
      } else {
        setOptions([]);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [inputValue, searchIndex]);

  // Handle city change
  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  return (
    <Select
      value={selectedCity}
      onChange={handleCityChange}
      onInputChange={setInputValue}
      options={options}
      placeholder={placeholder}
      className="citypicker"
      components={{ MenuList }}
      closeMenuOnSelect={true}
      noOptionsMessage={() =>
        inputValue.length < 2 ? "Start typing..." : "No matching stations"
      }
      menuIsOpen={inputValue.length >= 2}
    />
  );
};

export default CityPicker;
